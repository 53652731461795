<template>
  <div class="plntsprt_gallery_section">
    <div class="plntsprt_gallery_ftr_outer">
        <div class="plntsprt_gallery_ftr">
            <a href="#">
                <img src=https://pbs.twimg.com/ext_tw_video_thumb/1535138135692173313/pu/img/tTGNTke4bmqhvKYv.jpg>
                <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                <span>New</span>
            </a>
        </div>
    </div>
    <div class="plntsprt_gallery_list">
        <ul>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="https://livesoccerkisma.6lgx.com//assets/images/6.png" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="https://livesoccerkisma.6lgx.com//assets/images/2.png" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="https://livesoccerkisma.6lgx.com//assets/images/3.png" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="https://livesoccerkisma.6lgx.com//assets/images/4.png" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="http://pbs.twimg.com/profile_images/1513910237471731712/hFkuSIc__normal.jpg" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
            <li>
                <div class="plntsprt_gallery_box">
                    <a href="#">
                        <img src="https://livesoccerkisma.6lgx.com//assets/images/5.png" alt="">
                        <label>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</label>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>

export default {
    name:'MatchDetailGallery',
    data() {
        return{

        }
    },
}

</script>
